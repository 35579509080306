import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { getPartyBySlug } from "../data/parties";
import { Format } from "../helpers/Format";
import useLocale from "../hooks/useLocale";

const translation = {
  fr: {
    parti: "Groupement politique",
    suffragesListe: "Suffrages de liste",
    suffragesNominatifs: "Suffrages nominatifs",
    suffragesTotal: "Total suffrages",
    pourcentage: "%",
    mandats: "Mandats",
  },
  de: {
    parti: "Politische Gruppierung",
    suffragesListe: "Listenstimmen",
    suffragesNominatifs: "Kandidatenstimmen",
    suffragesTotal: "Alle Stimmen",
    pourcentage: "%",
    mandats: "Mandate",
  },
};

const CommuneTable = ({ data }) => {
  console.log("CommuneTable Render");
  console.log("CommuneTable data", data);

  const locale = useLocale();
  const t = translation[locale];

  const F = new Format(locale);

  const rows = data.results.listes;
  const hasMandats = rows[0]?.mandatsAttribues !== undefined;

  console.log("rows", rows);
  // rows.map((row) => console.log("row", getPartyBySlug(row.slug)?.tag));

  return (
    <>
      <TableContainer component={Paper} elevation={3} sx={{ mb: 2 }}>
        <Table sx={{ minWidth: 250 }} size="small" aria-label="Suffrages">
          <TableHead>
            <TableRow>
              <TableCell>{t.parti}</TableCell>
              <TableCell align="right">{t.suffragesListe}</TableCell>
              <TableCell align="right">{t.suffragesNominatifs}</TableCell>
              <TableCell align="right">{t.suffragesTotal}</TableCell>
              <TableCell align="right">{t.pourcentage}</TableCell>
              <>
                {hasMandats && <TableCell align="right">{t.mandats}</TableCell>}
              </>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.slug}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                className={"party party-" + row.slug}
              >
                <TableCell
                  component="th"
                  scope="row"
                  style={{ whiteSpace: "nowrap" }}
                >
                  {getPartyBySlug(row.slug)?.tag}
                </TableCell>
                <TableCell align="right">
                  {F.formatNumber(row.suffragesListe)}
                </TableCell>
                <TableCell align="right">
                  {F.formatNumber(row.suffragesNominatifs)}
                </TableCell>
                <TableCell align="right">
                  {F.formatNumber(row.suffragesTotal)}
                </TableCell>
                <TableCell align="right">
                  {F.formatPercent(row.pourcentage / 100)}
                </TableCell>
                <>
                  {hasMandats && (
                    <TableCell align="right">{row.mandatsAttribues}</TableCell>
                  )}
                </>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CommuneTable;
