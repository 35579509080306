export class Format {
  constructor(locale) {
    this.locale = locale === "de" ? "de-DE" : "fr-FR";
  }

  formatNumber(number) {
    return new Intl.NumberFormat(this.locale).format(number);
  }

  formatPercent(number) {
    return new Intl.NumberFormat(this.locale, {
      style: "percent",
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(number);
  }
}
