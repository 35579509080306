import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import * as d3 from "d3";
import * as topojson from "topojson-client";
import slugify from "../helpers/Slugify";
import "./MapChart.scss";
import useLocale from "../hooks/useLocale";

const topoJsonUrl = "/luxembourg_fr.json";

const MapChart = ({ data, width, height }) => {
  console.log("MapChart Render");
  console.log("MapChart data", data);

  const svgRef = useRef(null);

  const locale = useLocale();
  const communePath = locale === "de" ? "/de/gemeinde/" : "/fr/commune/";

  const navigate = useNavigate();
  const navigateToCommune = (event, slug) => {
    event.preventDefault();
    navigate(communePath + slug);
  };

  var electoralDistricts = ["centre", "est", "nord", "sud"];

  var projection = d3
    .geoMercator()
    .scale(42000)
    .center([6.13, 49.815])
    .translate([width / 2, height / 2]);

  var path = d3.geoPath().projection(projection);

  function drawDivisions(svg, mapDivision, path) {
    console.log("drawDivisions", mapDivision);

    if (mapDivision === "communes" || mapDivision === "cantons") {
      d3.json(topoJsonUrl)
        .then((topology) => {
          // console.log("topology", topology);

          // convert to geojson
          var objects =
            mapDivision === "cantons"
              ? topology.objects.cantons
              : topology.objects.communes;
          var geojson = topojson.feature(topology, objects);
          // console.log("geojson", geojson);

          // add links
          const links = svg
            .selectAll(null)
            .data(geojson.features)
            .enter()
            .append("a")
            .on("click", (event, datum) => {
              const commune = datum.properties.COMMUNE;
              const slug = slugify(commune);

              const hasData = data[slug]?.results?.majorite;
              if (hasData) {
                navigateToCommune(event, slug);
              }
            });
          // .attr("href", (datum) => {
          //   const commune = datum.properties.COMMUNE;
          //   const slug = slugify(commune);
          //   return communePath + slug;
          // });

          // append title elements to links (will show tooltip)
          links.append("title").text((datum) => {
            return datum.properties.COMMUNE;
          });

          // append path elements to links
          links
            .append("path")
            .attr("d", path)
            .on("mouseover", function (event, feature) {
              console.log("properties", feature.properties);
              // const commune = feature.properties.COMMUNE;
              // const slug = slugify(commune);
              // console.log("slug", slug);
              // console.log("data", data[slug].results?.total?.majorite);
            })
            .attr("class", (datum) => {
              const commune = datum.properties.COMMUNE;
              const slug = slugify(commune);
              //console.log("SLUG", commune, slug);
              // console.log("data", data[slug]);

              let majorite = data[slug]?.results?.majorite;
              let className = "";
              if (majorite) {
                className = " map-" + majorite;
              } else {
                // className = " map-none";
                console.log("No data for", commune, slug);
              }
              return mapDivision + className;
            });
        })
        .catch((err) => {
          console.log("Error occurred with loading map", err);
        });
    } else if (mapDivision === "circonscriptions") {
      d3.json(topoJsonUrl)
        .then((topology) => {
          // console.log("topojson", topology);

          // convert to geojson
          var geojson = topojson.feature(topology, topology.objects.cantons);
          // console.log("geojson", geojson);

          svg
            .selectAll(null)
            .data(electoralDistricts)
            .enter()
            .append("path")
            .attr("d", (datum) => {
              // Merge cantons per electoral district
              const geoCollection = topojson.merge(
                topology,
                topology.objects.cantons.geometries.filter((canton) => {
                  return (
                    canton.properties.CIRCONSCRIPTION.toLowerCase() ===
                    datum.toLowerCase()
                  );
                })
              );

              // const feature = {
              //   type: "Feature",
              //   properties: {},
              //   geometry: geoCollection,
              // };

              return path(geoCollection);
            })
            .attr("class", (datum) => {
              return mapDivision;
            });
        })
        .catch((err) => {
          console.log("Error occurred with loading map", err);
        });
    }
  }

  useEffect(() => {
    const svg = d3
      .select(svgRef.current)
      .attr("viewBox", "0 0 " + width + " " + height)
      .attr("preserveAspectRatio", "xMinYMin");

    drawDivisions(svg, "communes", path);
    drawDivisions(svg, "circonscriptions", path);
  }, []);

  return <svg ref={svgRef} />;
};

export default MapChart;
