import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BarChart from "../components/BarChart";
import { getPartyBySlug } from "../data/parties.js";
import useCirconscriptionData from "../hooks/useCirconscriptionData";
import ProgressAlert from "../components/ProgressAlert";
import useLocale from "../hooks/useLocale";
import circonscriptions from "../data/circonscriptions";

import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

const translation = {
  fr: {
    suffrages: "Suffrages circonscription",
    error: "Pas de données pour cette circonscription",
  },
  de: {
    suffrages: "Ergebnisse des Wahlbezirks",
    error: "Keine Daten für diesen Wahlbezirk",
  },
};

const slugLookup = {
  centre: "centre",
  zentrum: "centre",
  est: "est",
  osten: "est",
  nord: "nord",
  norden: "nord",
  sud: "sud",
  sueden: "sud",
};

const Circonscription = () => {
  const { slug } = useParams();

  console.log("Circonscription Render", slug);

  const realSlug = slugLookup[slug];

  const locale = useLocale();
  const t = translation[locale];

  const circonscription = circonscriptions[realSlug];

  // Fetch circonscription data from API
  const data = useCirconscriptionData(realSlug);

  const [barChartData, setBarChartData] = useState([]);

  // Prepare data for BarChart
  useEffect(() => {
    if (data.loading || data.error) return;
    const listes = data.data.results.listes.map((liste) => {
      const listeSlug = liste.slug;
      const party = getPartyBySlug(listeSlug);
      return {
        label: party?.tag,
        value: liste.pourcentage,
        class: party?.slug,
      };
    });
    setBarChartData(listes);
  }, [data]);

  if (data.error || !circonscription) {
    return <p>{t.error}</p>;
  }

  return (
    <>
      {data.loading ? (
        <p>Loading...</p>
      ) : (
        <>
          {barChartData.length > 0 && (
            <>
              <Paper elevation={3} sx={{ p: 2, m: 1 }}>
                <Typography variant="h5" component="h2" gutterBottom>
                  {t.suffrages} {circonscription.name[locale]}
                </Typography>

                <BarChart
                  id="barchart1"
                  data={barChartData}
                  width={400}
                  height={300}
                />

                <ProgressAlert stats={data.data.stats} />
              </Paper>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Circonscription;
