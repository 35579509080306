import { useEffect, useState } from "react";
import MapChart from "../components/MapChart";
import useMapData from "../hooks/useMapData";

const Map = () => {
  console.log("Map Render");

  // add random majority for testing
  // const partiesList = parties.map((party) => party.slug);
  // Object.values(communes).forEach((commune) => {
  //   commune["majorite"] =
  //     partiesList[Math.floor(Math.random() * partiesList.length)];
  // });

  // console.log(communes);

  const mapData = useMapData();

  return (
    <>
      <h2>Map Demo!</h2>
      {mapData.loading ? (
        <h2>Loading...</h2>
      ) : (
        <div style={{ maxWidth: "500px" }}>
          <MapChart data={mapData.data} width={650} height={850} />
        </div>
      )}
    </>
  );
};

export default Map;
