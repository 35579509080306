import { useState, useEffect } from "react";
import communes from "../data/communes.js";

export default function useMapData() {
  const [mapData, setMapData] = useState({
    data: {},
    loading: true,
  });

  const fetchData = async () => {
    // Get latest data from API
    try {
      const response = await fetch(
        `https://docs.lessentiel.lu/legislatives2023/data/COMMUNE.json`
      );
      const apiData = await response.json();
      console.log("fetchData data", apiData);

      // Merge API data with local data
      Object.entries(communes).forEach(([slug, values]) => {
        communes[slug] = { ...values, ...apiData[slug] };
      });
      console.log("fetchData communes", communes);
    } catch (error) {
      console.log("Error fetching data", error);
    } finally {
      console.log("fetchData finally");
      setMapData((prevState) => {
        return { ...prevState, data: communes, loading: false };
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return mapData;
}
