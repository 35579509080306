import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Map from "./pages/Map";
import Commune from "./pages/Commune";
import Circonscription from "./pages/Circonscription";
import CommuneWidget from "./widgets/Commune";
import CirconscriptionWidget from "./widgets/Circonscription";
import NoPage from "./pages/NoPage";
import "./App.scss";
import CssBaseline from "@mui/material/CssBaseline";

function App() {
  return (
    <BrowserRouter>
      <CssBaseline />
      <Routes>
        <Route path="/fr/">
          <Route index element={<Home />} />
          <Route path="map" element={<Map />} />
          <Route path="commune/:slug" element={<Commune />} />
          <Route path="circonscription/:slug" element={<Circonscription />} />
          <Route path="widget/commune/:slug" element={<CommuneWidget />} />
          <Route
            path="widget/circonscription/:slug"
            element={<CirconscriptionWidget />}
          />
          <Route path="*" element={<NoPage />} />
        </Route>
        <Route path="/de/">
          <Route index element={<Home />} />
          <Route path="map" element={<Map />} />
          <Route path="gemeinde/:slug" element={<Commune />} />
          <Route path="wahlbezirk/:slug" element={<Circonscription />} />
          <Route path="widget/gemeinde/:slug" element={<CommuneWidget />} />
          <Route
            path="widget/wahlbezirk/:slug"
            element={<CirconscriptionWidget />}
          />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
