const circonscriptions = {
  centre: {
    slug: "centre",
    name: { fr: "Centre", de: "Zentrum" },
  },
  est: {
    slug: "est",
    name: { fr: "Est", de: "Osten" },
  },
  nord: {
    slug: "nord",
    name: { fr: "Nord", de: "Norden" },
  },
  sud: {
    slug: "sud",
    name: { fr: "Sud", de: "Süden" },
  },
};

export default circonscriptions;
