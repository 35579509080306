import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { getParties } from "../data/parties";
import { Format } from "../helpers/Format";
import useLocale from "../hooks/useLocale";

const translation = {
  fr: {
    parti: "Parti",
    centre: "Centre",
    est: "Est",
    nord: "Nord",
    sud: "Sud",
    total: "Total",
  },
  de: {
    parti: "Partei",
    centre: "Zentrum",
    est: "Osten",
    nord: "Norden",
    sud: "Süden",
    total: "Total",
  },
};

const SuffragesTable = ({ data }) => {
  console.log("SuffragesTable Render");
  console.log("SuffragesTable data", data);

  const locale = useLocale();
  const t = translation[locale];

  const F = new Format(locale);

  const parties = getParties();

  const regions = [
    data["REGION"]["centre"],
    data["REGION"]["est"],
    data["REGION"]["nord"],
    data["REGION"]["sud"],
    data["PAYS"]["le-grand-duche-de-luxembourg"],
  ];

  let rows = [];

  parties.forEach((party) => {
    let row = { party: party.tag, slug: party.slug };

    regions.forEach((region) => {
      const liste = region.results?.listes?.find(
        (liste) => liste.numero === party.id
      );
      const suffrages = liste?.pourcentage || "0";
      const slug =
        region.slug === "le-grand-duche-de-luxembourg" ? "total" : region.slug;
      row[slug] = suffrages;
    });
    rows.push(row);
  });

  console.log("rows", rows);

  return (
    <>
      <TableContainer component={Paper} elevation={3} sx={{ mb: 2 }}>
        <Table sx={{ minWidth: 250 }} size="small" aria-label="Suffrages">
          <TableHead>
            <TableRow>
              <TableCell>{t.parti}</TableCell>
              <TableCell align="right">{t.centre}</TableCell>
              <TableCell align="right">{t.est}</TableCell>
              <TableCell align="right">{t.nord}</TableCell>
              <TableCell align="right">{t.sud}</TableCell>
              <TableCell align="right">{t.total}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.slug}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                className={"party party-" + row.slug}
              >
                <TableCell
                  component="th"
                  scope="row"
                  style={{ whiteSpace: "nowrap" }}
                >
                  {row.party}
                </TableCell>
                <TableCell align="right">
                  {F.formatPercent(row.centre / 100)}
                </TableCell>
                <TableCell align="right">
                  {F.formatPercent(row.est / 100)}
                </TableCell>
                <TableCell align="right">
                  {F.formatPercent(row.nord / 100)}
                </TableCell>
                <TableCell align="right">
                  {F.formatPercent(row.sud / 100)}
                </TableCell>
                <TableCell align="right">
                  {F.formatPercent(row.total / 100)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default SuffragesTable;
