import { useState, useEffect } from "react";
import communes from "../data/communes.js";

export default function useMapData(slug) {
  const [data, setData] = useState({
    data: {},
    loading: true,
    error: "",
  });

  const fetchData = async (slug) => {
    // Get latest data from API
    try {
      const response = await fetch(
        `https://docs.lessentiel.lu/legislatives2023/data/COMMUNE.json`
      );
      const apiData = await response.json();
      // console.log("fetchData data", apiData);

      const communeApiData = apiData[slug];
      const communeLocalData = communes[slug];

      const communeData = { ...communeLocalData, ...communeApiData };
      console.log("communeData", communeData);

      if (
        communeData?.nom?.fr === undefined ||
        communeData?.results?.listes === undefined
      ) {
        throw new Error("Commune not found");
      }

      setData((prevState) => {
        return {
          ...prevState,
          data: communeData,
          loading: false,
          error: "",
        };
      });
    } catch (error) {
      console.log("Error fetching data", error);

      setData((prevState) => {
        return {
          ...prevState,
          loading: false,
          error: error.message,
        };
      });
    }
  };

  useEffect(() => {
    fetchData(slug);
  }, []);

  return data;
}
