import { useState, useEffect } from "react";

export default function useCirconscriptionData(slug) {
  const [data, setData] = useState({
    data: {},
    loading: true,
    error: "",
  });

  const fetchData = async (slug) => {
    console.log("fetchData slug", slug);

    // Get latest data from API
    try {
      const response = await fetch(
        `https://docs.lessentiel.lu/legislatives2023/data/MERGED.json`
      );
      const apiData = await response.json();
      //console.log("fetchData data", apiData);

      const circData = apiData["REGION"][slug];

      if (circData === undefined) {
        throw new Error("Circonscription not found");
      }

      setData((prevState) => {
        return {
          ...prevState,
          data: circData,
          loading: false,
          error: "",
        };
      });
    } catch (error) {
      console.log("Error fetching data", error);

      setData((prevState) => {
        return {
          ...prevState,
          loading: false,
          error: error.message,
        };
      });
    }
  };

  useEffect(() => {
    fetchData(slug);
  }, []);

  return data;
}
