import { useMemo } from "react";
import { useLocation } from "react-router-dom";

// function useLocale() {
//   const [localee, setLocalee] = useState("fr");

//   const location = useLocation();
//   console.log(location.pathname);

//   useEffect(() => {
//     try {
//       const localeUrl = location.pathname.split("/")[1];
//       setLocalee(localeUrl === "de" ? "de" : "fr");
//     } catch (error) {
//       console.log("Error setting locale", error);
//     }
//   }, [location]);

//   return localee;
// }

const useLocale = () => {
  const location = useLocation();
  // console.log(location.pathname);

  const locale = useMemo(() => {
    try {
      const localeUrl = location.pathname.split("/")[1];
      return localeUrl === "de" ? "de" : "fr";
    } catch (error) {
      console.log("Error setting locale", error);
    }
    return "fr";
  }, [location]);

  return locale;
};

export default useLocale;
