var communes = {
  beaufort: {
    nom: {
      fr: "Beaufort",
      en: "Beaufort",
      de: "Befort",
      lb: "Beefort",
    },
    circonscription: "est",
  },
  bech: {
    nom: {
      fr: "Bech",
      en: "Bech",
      de: "Bech",
      lb: "Bech",
    },
    circonscription: "est",
  },
  beckerich: {
    nom: {
      fr: "Beckerich",
      en: "Beckerich",
      de: "Beckerich",
      lb: "Biekerech",
    },
    circonscription: "nord",
  },
  berdorf: {
    nom: {
      fr: "Berdorf",
      en: "Berdorf",
      de: "Berdorf",
      lb: "Bäerdref",
    },
    circonscription: "est",
  },
  bertrange: {
    nom: {
      fr: "Bertrange",
      en: "Bertrange",
      de: "Bartringen",
      lb: "Bartreng",
    },
    circonscription: "centre",
  },
  bettembourg: {
    nom: {
      fr: "Bettembourg",
      en: "Bettembourg",
      de: "Bettemburg",
      lb: "Beetebuerg",
    },
    circonscription: "sud",
  },
  bettendorf: {
    nom: {
      fr: "Bettendorf",
      en: "Bettendorf",
      de: "Bettendorf",
      lb: "Bettenduerf",
    },
    circonscription: "nord",
  },
  betzdorf: {
    nom: {
      fr: "Betzdorf",
      en: "Betzdorf",
      de: "Betzdorf",
      lb: "Betzder",
    },
    circonscription: "est",
  },
  bissen: {
    nom: {
      fr: "Bissen",
      en: "Bissen",
      de: "Bissen",
      lb: "Biissen",
    },
    circonscription: "centre",
  },
  biwer: {
    nom: {
      fr: "Biwer",
      en: "Biwer",
      de: "Biwer",
      lb: "Biwer",
    },
    circonscription: "est",
  },
  boulaide: {
    nom: {
      fr: "Boulaide",
      en: "Boulaide",
      de: "Bauschleiden",
      lb: "Bauschelt",
    },
    circonscription: "nord",
  },
  bourscheid: {
    nom: {
      fr: "Bourscheid",
      en: "Bourscheid",
      de: "Burscheid",
      lb: "Buerschent",
    },
    circonscription: "nord",
  },
  "bous-waldbredimus": {
    nom: {
      fr: "Bous-Waldbredimus",
      en: "Bous-Waldbredimus",
      de: "Bous-Waldbredimus",
      lb: "Bous-Waldbriedemes",
    },
    circonscription: "est",
  },
  clervaux: {
    nom: {
      fr: "Clervaux",
      en: "Clervaux",
      de: "Clerf",
      lb: "Clierf",
    },
    circonscription: "nord",
  },
  "colmar-berg": {
    nom: {
      fr: "Colmar-Berg",
      en: "Colmar-Berg",
      de: "Colmar-Berg",
      lb: "Colmer-Bierg",
    },
    circonscription: "centre",
  },
  consdorf: {
    nom: {
      fr: "Consdorf",
      en: "Consdorf",
      de: "Consdorf",
      lb: "Konsdref",
    },
    circonscription: "est",
  },
  contern: {
    nom: {
      fr: "Contern",
      en: "Contern",
      de: "Contern",
      lb: "Conter",
    },
    circonscription: "centre",
  },
  dalheim: {
    nom: {
      fr: "Dalheim",
      en: "Dalheim",
      de: "Dalheim",
      lb: "Duelem",
    },
    circonscription: "est",
  },
  diekirch: {
    nom: {
      fr: "Diekirch",
      en: "Diekirch",
      de: "Diekirch",
      lb: "Dikrech",
    },
    circonscription: "nord",
  },
  differdange: {
    nom: {
      fr: "Differdange",
      en: "Differdange",
      de: "Differdingen",
      lb: "Déifferdeng",
    },
    circonscription: "sud",
  },
  dippach: {
    nom: {
      fr: "Dippach",
      en: "Dippach",
      de: "Dippach",
      lb: "Dippech",
    },
    circonscription: "sud",
  },
  dudelange: {
    nom: {
      fr: "Dudelange",
      en: "Dudelange",
      de: "Düdelingen",
      lb: "Diddeleng",
    },
    circonscription: "sud",
  },
  echternach: {
    nom: {
      fr: "Echternach",
      en: "Echternach",
      de: "Echternach",
      lb: "Iechternach",
    },
    circonscription: "est",
  },
  ell: {
    nom: {
      fr: "Ell",
      en: "Ell",
      de: "Ell",
      lb: "Ell",
    },
    circonscription: "nord",
  },
  "erpeldange-sur-sure": {
    nom: {
      fr: "Erpeldange-sur-Sûre",
      en: "Erpeldange-sur-Sûre",
      de: "Erpeldingen an der Sauer",
      lb: "Ierpeldeng-Sauer",
    },
    circonscription: "nord",
  },
  "esch-sur-alzette": {
    nom: {
      fr: "Esch-sur-Alzette",
      en: "Esch-sur-Alzette",
      de: "Esch an der Alzette",
      lb: "Esch-Uelzecht",
    },
    circonscription: "sud",
  },
  "esch-sur-sure": {
    nom: {
      fr: "Esch-sur-Sûre",
      en: "Esch-sur-Sûre",
      de: "Esch an der Sauer",
      lb: "Esch-Sauer",
    },
    circonscription: "nord",
  },
  ettelbruck: {
    nom: {
      fr: "Ettelbruck",
      en: "Ettelbruck",
      de: "Ettelbrück",
      lb: "Ettelbréck",
    },
    circonscription: "nord",
  },
  feulen: {
    nom: {
      fr: "Feulen",
      en: "Feulen",
      de: "Feulen",
      lb: "Feelen",
    },
    circonscription: "nord",
  },
  fischbach: {
    nom: {
      fr: "Fischbach",
      en: "Fischbach",
      de: "Fischbach",
      lb: "Fëschbech",
    },
    circonscription: "centre",
  },
  flaxweiler: {
    nom: {
      fr: "Flaxweiler",
      en: "Flaxweiler",
      de: "Flaxweiler",
      lb: "Fluessweiler",
    },
    circonscription: "est",
  },
  frisange: {
    nom: {
      fr: "Frisange",
      en: "Frisange",
      de: "Frisingen",
      lb: "Fréiseng",
    },
    circonscription: "sud",
  },
  garnich: {
    nom: {
      fr: "Garnich",
      en: "Garnich",
      de: "Garnich",
      lb: "Garnech",
    },
    circonscription: "sud",
  },
  goesdorf: {
    nom: {
      fr: "Goesdorf",
      en: "Goesdorf",
      de: "Goesdorf",
      lb: "Géisdref",
    },
    circonscription: "nord",
  },
  grevenmacher: {
    nom: {
      fr: "Grevenmacher",
      en: "Grevenmacher",
      de: "Grevenmacher",
      lb: "Gréiwemaacher",
    },
    circonscription: "est",
  },
  "groussbus-wal": {
    nom: {
      fr: "Groussbus-Wal",
      en: "Grosbous-Wahl",
      de: "Grosbus-Wahl",
      lb: "Groussbus-Wal",
    },
    circonscription: "nord",
  },
  habscht: {
    nom: {
      fr: "Habscht",
      en: "Hobscheid",
      de: "Hobscheid",
      lb: "Habscht",
    },
    circonscription: "sud",
  },
  heffingen: {
    nom: {
      fr: "Heffingen",
      en: "Heffingen",
      de: "Heffingen",
      lb: "Hiefenech",
    },
    circonscription: "centre",
  },
  helperknapp: {
    nom: {
      fr: "Helperknapp",
      en: "Helperknapp",
      de: "Helperknapp",
      lb: "Helperknapp",
    },
    circonscription: "centre",
  },
  hesperange: {
    nom: {
      fr: "Hesperange",
      en: "Hesperange",
      de: "Hesperingen",
      lb: "Hesper",
    },
    circonscription: "centre",
  },
  junglinster: {
    nom: {
      fr: "Junglinster",
      en: "Junglinster",
      de: "Junglinster",
      lb: "Jonglënster",
    },
    circonscription: "est",
  },
  kaerjeng: {
    nom: {
      fr: "Käerjeng",
      en: "Käerjeng",
      de: "Käerjeng",
      lb: "Käerjeng",
    },
    circonscription: "sud",
  },
  kayl: {
    nom: {
      fr: "Kayl",
      en: "Kayl",
      de: "Kayl",
      lb: "Keel",
    },
    circonscription: "sud",
  },
  kehlen: {
    nom: {
      fr: "Kehlen",
      en: "Kehlen",
      de: "Kehlen",
      lb: "Kielen",
    },
    circonscription: "sud",
  },
  kiischpelt: {
    nom: {
      fr: "Kiischpelt",
      en: "Kiischpelt",
      de: "Kiischpelt",
      lb: "Kiischpelt",
    },
    circonscription: "nord",
  },
  koerich: {
    nom: {
      fr: "Koerich",
      en: "Koerich",
      de: "Koerich",
      lb: "Käerch",
    },
    circonscription: "sud",
  },
  kopstal: {
    nom: {
      fr: "Kopstal",
      en: "Kopstal",
      de: "Kopstal",
      lb: "Koplescht",
    },
    circonscription: "sud",
  },
  "lac-de-la-haute-sure": {
    nom: {
      fr: "Lac de la Haute-Sûre",
      en: "Lac de la Haute-Sûre",
      de: "Lac de la Haute-Sûre",
      lb: "Stauséigemeng",
    },
    circonscription: "nord",
  },
  larochette: {
    nom: {
      fr: "Larochette",
      en: "Larochette",
      de: "Fels",
      lb: "Fiels",
    },
    circonscription: "centre",
  },
  lenningen: {
    nom: {
      fr: "Lenningen",
      en: "Lenningen",
      de: "Lenningen",
      lb: "Lenneng",
    },
    circonscription: "est",
  },
  leudelange: {
    nom: {
      fr: "Leudelange",
      en: "Leudelange",
      de: "Leudelingen",
      lb: "Leideleng",
    },
    circonscription: "sud",
  },
  lintgen: {
    nom: {
      fr: "Lintgen",
      en: "Lintgen",
      de: "Lintgen",
      lb: "Lëntgen",
    },
    circonscription: "centre",
  },
  lorentzweiler: {
    nom: {
      fr: "Lorentzweiler",
      en: "Lorentzweiler",
      de: "Lorentzweiler",
      lb: "Luerenzweiler",
    },
    circonscription: "centre",
  },
  luxembourg: {
    nom: {
      fr: "Luxembourg",
      en: "Luxembourg",
      de: "Luxemburg",
      lb: "Lëtzebuerg",
    },
    circonscription: "centre",
  },
  mamer: {
    nom: {
      fr: "Mamer",
      en: "Mamer",
      de: "Mamer",
      lb: "Mamer",
    },
    circonscription: "sud",
  },
  manternach: {
    nom: {
      fr: "Manternach",
      en: "Manternach",
      de: "Manternach",
      lb: "Manternach",
    },
    circonscription: "est",
  },
  mersch: {
    nom: {
      fr: "Mersch",
      en: "Mersch",
      de: "Mersch",
      lb: "Miersch",
    },
    circonscription: "centre",
  },
  mertert: {
    nom: {
      fr: "Mertert",
      en: "Mertert",
      de: "Mertert",
      lb: "Mäertert",
    },
    circonscription: "est",
  },
  mertzig: {
    nom: {
      fr: "Mertzig",
      en: "Mertzig",
      de: "Mertzig",
      lb: "Mäerzeg",
    },
    circonscription: "nord",
  },
  mondercange: {
    nom: {
      fr: "Mondercange",
      en: "Mondercange",
      de: "Monnerich",
      lb: "Monnerech",
    },
    circonscription: "sud",
  },
  "mondorf-les-bains": {
    nom: {
      fr: "Mondorf-les-Bains",
      en: "Mondorf-les-Bains",
      de: "Bad-Mondorf",
      lb: "Munneref",
    },
    circonscription: "est",
  },
  niederanven: {
    nom: {
      fr: "Niederanven",
      en: "Niederanven",
      de: "Niederanwen",
      lb: "Nidderaanwen",
    },
    circonscription: "centre",
  },
  nommern: {
    nom: {
      fr: "Nommern",
      en: "Nommern",
      de: "Nommern",
      lb: "Noumer",
    },
    circonscription: "centre",
  },
  "parc-hosingen": {
    nom: {
      fr: "Parc Hosingen",
      en: "Parc Hosingen",
      de: "Parc Hosingen",
      lb: "Park Housen",
    },
    circonscription: "nord",
  },
  petange: {
    nom: {
      fr: "Pétange",
      en: "Pétange",
      de: "Petingen",
      lb: "Péiteng",
    },
    circonscription: "sud",
  },
  preizerdaul: {
    nom: {
      fr: "Préizerdaul",
      en: "Preizerdaul",
      de: "Preizerdaul",
      lb: "Préizerdaul",
    },
    circonscription: "nord",
  },
  putscheid: {
    nom: {
      fr: "Putscheid",
      en: "Putscheid",
      de: "Pütscheid",
      lb: "Pëtschent",
    },
    circonscription: "nord",
  },
  rambrouch: {
    nom: {
      fr: "Rambrouch",
      en: "Rambrouch",
      de: "Rambruch",
      lb: "Rammerech",
    },
    circonscription: "nord",
  },
  "reckange-sur-mess": {
    nom: {
      fr: "Reckange-sur-Mess",
      en: "Reckange-sur-Mess",
      de: "Reckingen",
      lb: "Reckeng op der Mess",
    },
    circonscription: "sud",
  },
  "redange-attert": {
    nom: {
      fr: "Redange/Attert",
      en: "Redange/Attert",
      de: "Redingen an der Attert",
      lb: "Réiden op der Atert",
    },
    circonscription: "nord",
  },
  reisdorf: {
    nom: {
      fr: "Reisdorf",
      en: "Reisdorf",
      de: "Reisdorf",
      lb: "Reisduerf",
    },
    circonscription: "nord",
  },
  remich: {
    nom: {
      fr: "Remich",
      en: "Remich",
      de: "Remich",
      lb: "Réimech",
    },
    circonscription: "est",
  },
  roeser: {
    nom: {
      fr: "Roeser",
      en: "Roeser",
      de: "Roeser",
      lb: "Réiser",
    },
    circonscription: "sud",
  },
  "rosport-mompach": {
    nom: {
      fr: "Rosport-Mompach",
      en: "Rosport-Mompach",
      de: "Rosport-Mompach",
      lb: "Rouspert-Mompech",
    },
    circonscription: "est",
  },
  rumelange: {
    nom: {
      fr: "Rumelange",
      en: "Rumelange",
      de: "Rümelingen",
      lb: "Rëmeleng",
    },
    circonscription: "sud",
  },
  saeul: {
    nom: {
      fr: "Saeul",
      en: "Saeul",
      de: "Saeul",
      lb: "Sëll",
    },
    circonscription: "nord",
  },
  sandweiler: {
    nom: {
      fr: "Sandweiler",
      en: "Sandweiler",
      de: "Sandweiler",
      lb: "Sandweiler",
    },
    circonscription: "centre",
  },
  sanem: {
    nom: {
      fr: "Sanem",
      en: "Sanem",
      de: "Sassenheim",
      lb: "Suessem",
    },
    circonscription: "sud",
  },
  schengen: {
    nom: {
      fr: "Schengen",
      en: "Schengen",
      de: "Schengen",
      lb: "Schengen",
    },
    circonscription: "est",
  },
  schieren: {
    nom: {
      fr: "Schieren",
      en: "Schieren",
      de: "Schieren",
      lb: "Schieren",
    },
    circonscription: "nord",
  },
  schifflange: {
    nom: {
      fr: "Schifflange",
      en: "Schifflange",
      de: "Schifflingen",
      lb: "Schëffleng",
    },
    circonscription: "sud",
  },
  schuttrange: {
    nom: {
      fr: "Schuttrange",
      en: "Schuttrange",
      de: "Schüttringen",
      lb: "Schëtter",
    },
    circonscription: "centre",
  },
  stadtbredimus: {
    nom: {
      fr: "Stadtbredimus",
      en: "Stadtbredimus",
      de: "Stadtbredimus",
      lb: "Stadbriedemes",
    },
    circonscription: "est",
  },
  steinfort: {
    nom: {
      fr: "Steinfort",
      en: "Steinfort",
      de: "Steinfort",
      lb: "Stengefort",
    },
    circonscription: "sud",
  },
  steinsel: {
    nom: {
      fr: "Steinsel",
      en: "Steinsel",
      de: "Steinsel",
      lb: "Steesel",
    },
    circonscription: "centre",
  },
  strassen: {
    nom: {
      fr: "Strassen",
      en: "Strassen",
      de: "Strassen",
      lb: "Stroossen",
    },
    circonscription: "centre",
  },
  tandel: {
    nom: {
      fr: "Tandel",
      en: "Tandel",
      de: "Tandel",
      lb: "Tandel",
    },
    circonscription: "nord",
  },
  troisvierges: {
    nom: {
      fr: "Troisvierges",
      en: "Troisvierges",
      de: "Ulflingen",
      lb: "Ëlwen",
    },
    circonscription: "nord",
  },
  useldange: {
    nom: {
      fr: "Useldange",
      en: "Useldange",
      de: "Useldingen",
      lb: "Useldeng",
    },
    circonscription: "nord",
  },
  "vallee-de-l-ernz": {
    nom: {
      fr: "Vallée de l'Ernz",
      en: "Vallée de l'Ernz",
      de: "Vallée de l'Ernz",
      lb: "Ärenzdallgemeng",
    },
    circonscription: "nord",
  },
  vianden: {
    nom: {
      fr: "Vianden",
      en: "Vianden",
      de: "Vianden",
      lb: "Veianen",
    },
    circonscription: "nord",
  },
  vichten: {
    nom: {
      fr: "Vichten",
      en: "Vichten",
      de: "Vichten",
      lb: "Viichten",
    },
    circonscription: "nord",
  },
  waldbillig: {
    nom: {
      fr: "Waldbillig",
      en: "Waldbillig",
      de: "Waldbillig",
      lb: "Waldbëlleg",
    },
    circonscription: "est",
  },
  walferdange: {
    nom: {
      fr: "Walferdange",
      en: "Walferdange",
      de: "Walferdingen",
      lb: "Walfer",
    },
    circonscription: "centre",
  },
  "weiler-la-tour": {
    nom: {
      fr: "Weiler-la-Tour",
      en: "Weiler-la-Tour",
      de: "Weiler zum Turm",
      lb: "Weiler zum Tuer",
    },
    circonscription: "centre",
  },
  weiswampach: {
    nom: {
      fr: "Weiswampach",
      en: "Weiswampach",
      de: "Weiswampach",
      lb: "Wäiswampech",
    },
    circonscription: "nord",
  },
  wiltz: {
    nom: {
      fr: "Wiltz",
      en: "Wiltz",
      de: "Wiltz",
      lb: "Wolz",
    },
    circonscription: "nord",
  },
  wincrange: {
    nom: {
      fr: "Wincrange",
      en: "Wincrange",
      de: "Wintger",
      lb: "Wëntger",
    },
    circonscription: "nord",
  },
  winseler: {
    nom: {
      fr: "Winseler",
      en: "Winseler",
      de: "Winseler",
      lb: "Wanseler",
    },
    circonscription: "nord",
  },
  wormeldange: {
    nom: {
      fr: "Wormeldange",
      en: "Wormeldange",
      de: "Wormeldingen",
      lb: "Wuermer",
    },
    circonscription: "est",
  },
};

export default communes;
