// import { Component } from "react";
import { useEffect, useRef, useState, useCallback } from "react";
import { Format } from "../helpers/Format";
import useLocale from "../hooks/useLocale";
import * as d3 from "d3";
import useResizeObserver from "../hooks/useResizeObserver";
import "./BarChart.scss";

function debounce(fn, ms) {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

const BarChart = ({ data, width, height }) => {
  const svgRef = useRef(null);

  console.log("BarChart Render");
  console.log("BarChart data", data);

  const locale = useLocale();

  const F = new Format(locale);

  const mode = "horizontal";

  const [svgWidth, setSvgWidth] = useState(0);
  const [svgHeight, setSvgHeight] = useState(0);

  const onResize = useCallback((target) => {
    // Handle the resize event
    debounce(() => {
      console.log("onResize", target.clientWidth, target.clientHeight);
      setSvgWidth(target.clientWidth);
      setSvgHeight(target.clientHeight);
    }, 3000)();
  }, []);

  useResizeObserver(svgRef, onResize);

  useEffect(() => {
    console.log(
      "useEffect",
      svgRef.current.clientWidth,
      svgRef.current.clientHeight
    );

    setSvgWidth(svgRef.current.clientWidth);
    setSvgHeight(svgRef.current.clientHeight);
  }, []);

  const barSpacing = 5;
  const labelsWidth = 160;
  const valuesWidth = 60;

  const barThickness =
    (mode === "vertical" ? width : height) / data.length - barSpacing;
  const fontSize = barThickness * 0.8;

  const max = Math.max(...data.map((d) => d.value));

  // Draw chart

  useEffect(() => {
    if (svgWidth === 0) return;

    console.log("REF", svgRef.current);

    // width = svgRef.current.clientWidth;
    const barsWidth = svgWidth - labelsWidth - valuesWidth;

    console.log("svgWidth", svgWidth);
    console.log("svgRef.current.clientWidth", svgRef.current.clientWidth);

    if (mode === "vertical") {
      const svg = d3.select(svgRef.current).attr("class", "bar-chart");
      svg
        .selectAll("rect")
        .data(data)
        .enter()
        .append("rect")
        .attr("x", (d, i) => (i * width) / data.length)
        .attr("width", width / data.length - 5)
        .attr("y", (d, i) => height - (height * d.value) / max)
        .attr("height", (d, i) => (height * d.value) / max)
        .attr("class", (d, i) => "bar " + d.class);
    } else {
      const svg = d3.select(svgRef.current).attr("class", "bar-chart");

      svg.selectAll("g").remove();

      const gWrapper = svg.append("g");
      gWrapper.attr("transform", "translate(" + labelsWidth + ", 0)");

      // const gBars = gWrapper.append("g").attr("transform", "scale(.25, 1)");
      const gBars = gWrapper.append("g");
      const gValues = gWrapper.append("g");
      const gLabels = gWrapper.append("g");

      // Bars
      gBars
        .selectAll("*")
        .data(data)
        .enter()
        .append("rect")
        .attr("x", (d, i) => 0)
        .attr("width", (d, i) => (d.value / max) * barsWidth)
        .attr("y", (d, i) => i * (barThickness + barSpacing))
        .attr("height", barThickness)
        .attr("class", (d, i) => "bar " + d.class);

      // Values
      gValues
        .selectAll("*")
        .data(data)
        .enter()
        .append("text")
        .text((d, i) => F.formatPercent(d.value / 100))
        .attr("class", "value")
        .attr("x", (d, i) => (d.value / max) * barsWidth + barSpacing)
        .attr("y", (d, i) => (i + 0.5) * (barThickness + barSpacing))
        .attr("alignment-baseline", "middle");
      //.attr("transform", (d, i) => "translate(0, " + ((i+.5) * barThickness + fontSize/2) + ")");
      //.attr("transform", (d, i) => "translate(0, " + ((i+1) * (barThickness + barSpacing) ) + ")");

      // Labels
      gLabels
        .selectAll("*")
        .data(data)
        .enter()
        .append("text")
        .text((d, i) => d.label)
        .attr("class", "label")
        .attr("x", (d, i) => -labelsWidth)
        .attr("y", (d, i) => (i + 0.5) * (barThickness + barSpacing))
        .attr("alignment-baseline", "middle");
    }
  }, [svgWidth, svgHeight]);

  return <svg ref={svgRef} width="100%" height={height} />;
};

export default BarChart;
