import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { getParties } from "../data/parties";
import { Format } from "../helpers/Format";
import useLocale from "../hooks/useLocale";

const translation = {
  fr: {
    parti: "Parti",
    mandats: "Mandats",
    suffrages: "Suffrages",
  },
  de: {
    parti: "Partei",
    mandats: "Mandate",
    suffrages: "Stimmen",
  },
};

const SummaryTable = ({ data }) => {
  console.log("SummaryTable Render");
  console.log("SummaryTable data", data);

  const locale = useLocale();
  const t = translation[locale];

  const F = new Format(locale);

  const parties = getParties();

  const country = data["PAYS"]["le-grand-duche-de-luxembourg"];

  let rows = [];

  parties.forEach((party) => {
    let row = { party: party.tag, slug: party.slug };

    const liste = country.results?.listes?.find(
      (liste) => liste.numero === party.id
    );
    const suffrages = liste?.pourcentage || "0";
    const mandats = liste?.mandatsAttribues || "0";

    row.suffrages = suffrages;
    row.mandats = mandats;

    rows.push(row);
  });

  console.log("rows", rows);

  return (
    <>
      <TableContainer component={Paper} elevation={3} sx={{ mb: 2 }}>
        <Table sx={{ minWidth: 250 }} size="small" aria-label="Suffrages">
          <TableHead>
            <TableRow>
              <TableCell>{t.parti}</TableCell>
              <TableCell align="right">{t.suffrages}</TableCell>
              <TableCell align="right">{t.mandats}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.slug}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                className={"party party-" + row.slug}
              >
                <TableCell
                  component="th"
                  scope="row"
                  style={{ whiteSpace: "nowrap" }}
                >
                  {row.party}
                </TableCell>
                <TableCell align="right">
                  {F.formatPercent(row.suffrages / 100)}
                </TableCell>
                <TableCell align="right">{row.mandats}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default SummaryTable;
