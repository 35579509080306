import Alert from "@mui/material/Alert";
import useLocale from "../hooks/useLocale";

// Fix this shit and use a proper internationalisation library that doesn't crash the server in production...
const getMessageText = (locale, commune, complete, total, percent) => {
  if (locale === "de") {
    if (commune) {
      return `In ${commune} haben ${complete} von ${total} (${percent}%) Wahllokale ihre kompletten Resultate übertragen.`;
    } else {
      return `${complete} von ${total} (${percent}%) Wahllokale haben ihre kompletten Resultate übertragen.`;
    }
  } else {
    const communeText = commune ? `À ${commune}, ` : "";
    return `${communeText}${complete} bureaux sur ${total} (${percent}%) ont transmis les résultats complets.`;
  }
};

const ProgressAlert = ({ stats, commune }) => {
  console.log("ProgressAlert Render", stats);

  const locale = useLocale();

  if (stats === undefined) return null;

  const total = stats.bureaux.total;
  const complete = stats.bureaux.complets;
  const percent = Math.round((complete / total) * 100);

  const message = getMessageText(locale, commune, complete, total, percent);

  return (
    <Alert variant="outlined" severity="info" sx={{ mb: 2 }}>
      {message}
    </Alert>
  );
};

export default ProgressAlert;
