import MandatsTable from "../components/MandatsTable";
import useCountryData from "../hooks/useCountryData";
import SuffragesTable from "../components/SuffragesTable";
import SummaryTable from "../components/SummaryTable";
import CommuneSelect from "../components/CommuneSelect";
import CirconscriptionSelect from "../components/CirconscriptionSelect";
import ProgressAlert from "../components/ProgressAlert";
import useLocale from "../hooks/useLocale";

import MapChart from "../components/MapChart";
import useMapData from "../hooks/useMapData";

import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

const translation = {
  fr: {
    title: "Législatives 2023",
    mandats: "Répartition des mandats",
    suffrages: "Répartition des suffrages",
    detail: "Tendance nationale",
  },
  de: {
    title: "Chamberwahlen 2023",
    mandats: "Verteilung der Mandate",
    suffrages: "Verteilung der Stimmen",
    detail: "Nationale Tendenz",
  },
};

const Home = () => {
  console.log("Home Render");

  const locale = useLocale();
  const t = translation[locale];

  const data = useCountryData();
  const mapData = useMapData();

  return (
    <>
      <Container maxWidth="lg" sx={{ m: 0, p: 1 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          {t.title}
        </Typography>
        {data.loading ? (
          <p>Loading...</p>
        ) : (
          <>
            <ProgressAlert
              stats={data?.data?.PAYS["le-grand-duche-de-luxembourg"]?.stats}
            />

            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={6}>
                <CirconscriptionSelect />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <CommuneSelect />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                {mapData.loading ? (
                  <p>Loading...</p>
                ) : (
                  <div style={{ maxWidth: "500px" }}>
                    <MapChart data={mapData.data} width={650} height={850} />
                  </div>
                )}
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <Typography variant="h5" component="h2" gutterBottom>
                  {t.detail}
                </Typography>
                <SummaryTable data={data.data} />
              </Grid>

              <Grid item xs={12} md={12} lg={6}>
                <Typography variant="h5" component="h2" gutterBottom>
                  {t.mandats}
                </Typography>
                <MandatsTable data={data.data} />
              </Grid>

              <Grid item xs={12} md={12} lg={6}>
                <Typography variant="h5" component="h2" gutterBottom>
                  {t.suffrages}
                </Typography>
                <SuffragesTable data={data.data} />
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    </>
  );
};

export default Home;
