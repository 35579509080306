import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import communes from "../data/communes";
import useLocale from "../hooks/useLocale";

const translation = {
  fr: {
    commune: "Commune",
  },
  de: {
    commune: "Gemeinde",
  },
};

const CommuneSelect = () => {
  const locale = useLocale();
  const t = translation[locale];

  let navigate = useNavigate();
  const routeChange = (slug) => {
    let path = locale === "de" ? "/de/gemeinde/" : "/fr/commune/";
    navigate(path + slug);
  };

  const communeList = Object.entries(communes).map((commune) => {
    const [slug, communeData] = commune;
    return {
      label: communeData.nom.fr,
      slug: slug,
    };
  });

  // console.log("communeList", communeList);

  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={communeList}
      size="small"
      sx={{ width: "100%" }}
      onChange={(event, newValue) => {
        console.log(newValue);
        routeChange(newValue.slug);
      }}
      renderInput={(params) => <TextField {...params} label={t.commune} />}
    />
  );
};

export default CommuneSelect;
