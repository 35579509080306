import { useState, useEffect } from "react";

export default function useCountryData() {
  const [data, setData] = useState({
    data: {},
    loading: true,
  });

  const fetchData = async (slug) => {
    // Get latest data from API
    try {
      const response = await fetch(
        `https://docs.lessentiel.lu/legislatives2023/data/MERGED.json`
      );
      const apiData = await response.json();
      // console.log("fetchData data", apiData);

      setData((prevState) => {
        return {
          ...prevState,
          data: apiData,
          loading: false,
        };
      });
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return data;
}
