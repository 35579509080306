import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BarChart from "../components/BarChart";
import { getPartyBySlug } from "../data/parties.js";
import useCommuneData from "../hooks/useCommuneData";
import ProgressAlert from "../components/ProgressAlert";
import useLocale from "../hooks/useLocale";

import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

const translation = {
  fr: {
    suffrages: "Suffrages pour ",
    error: "Pas de données pour cette commune",
  },
  de: {
    suffrages: "Stimmen für ",
    error: "Keine Daten für diese Gemeinde",
  },
};

const Commune = () => {
  const { slug } = useParams();

  console.log("Commune widget render", slug);

  const locale = useLocale();
  const t = translation[locale];

  // Fetch commune data from API
  const data = useCommuneData(slug);

  const [barChartData, setBarChartData] = useState([]);

  // Prepare data for BarChart
  useEffect(() => {
    if (data.loading || data.error) return;
    const listes = data.data.results.listes.map((liste) => {
      const listeSlug = liste.slug;
      const party = getPartyBySlug(listeSlug);
      return {
        label: party?.tag,
        value: liste.pourcentage,
        class: party?.slug,
      };
    });
    setBarChartData(listes);
  }, [data]);

  if (data.error) {
    return <p>{t.error}</p>;
  }

  return (
    <>
      {data.loading ? (
        <p>Loading...</p>
      ) : (
        <>
          {barChartData.length > 0 && (
            <>
              <Paper elevation={3} sx={{ p: 2, m: 1 }}>
                <Typography variant="h5" component="h2" gutterBottom>
                  {t.suffrages}
                  {data.data.nom.fr}
                </Typography>

                <BarChart
                  id="barchart1"
                  data={barChartData}
                  width={400}
                  height={300}
                />

                <ProgressAlert stats={data.data.stats} />
              </Paper>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Commune;
