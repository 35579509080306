import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import BarChart from "../components/BarChart";
import { getPartyBySlug } from "../data/parties.js";
import CommuneTable from "../components/CommuneTable";
import useCirconscriptionData from "../hooks/useCirconscriptionData";
import ProgressAlert from "../components/ProgressAlert";
import useLocale from "../hooks/useLocale";
import circonscriptions from "../data/circonscriptions";

import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

const translation = {
  fr: {
    suffrages: "Répartition des suffrages",
    detail: "Résultats détaillés",
    map: "Carte",
    mapUrl: "/fr/",
    error: "Pas de données pour cette circonscription",
  },
  de: {
    suffrages: "Verteilung der Stimmen",
    detail: "Detaillierte Resultate",
    map: "Karte",
    mapUrl: "/de/",
    error: "Keine Daten für diesen Wahlbezirk",
  },
};

const slugLookup = {
  centre: "centre",
  zentrum: "centre",
  est: "est",
  osten: "est",
  nord: "nord",
  norden: "nord",
  sud: "sud",
  sueden: "sud",
};

const Circonscription = () => {
  const { slug } = useParams();

  console.log("Circonscription Render", slug);

  const realSlug = slugLookup[slug];

  const locale = useLocale();
  const t = translation[locale];

  const circonscription = circonscriptions[realSlug];

  const navigate = useNavigate();
  const navigateToMap = (event) => {
    event.preventDefault();
    navigate(t.mapUrl);
  };

  // Fetch circonscription data from API
  const data = useCirconscriptionData(realSlug);

  const [barChartData, setBarChartData] = useState([]);

  // Prepare data for BarChart
  useEffect(() => {
    if (data.loading || data.error) return;
    const listes = data.data.results.listes.map((liste) => {
      const listeSlug = liste.slug;
      const party = getPartyBySlug(listeSlug);
      return {
        label: party?.tag,
        value: liste.pourcentage,
        class: party?.slug,
      };
    });
    setBarChartData(listes);
  }, [data]);

  if (data.error || !circonscription) {
    return <p>{t.error}</p>;
  }

  return (
    <>
      {data.loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <Container maxWidth="lg" sx={{ m: 0, p: 1 }}>
            <Box sx={{ display: "flex", mb: 1 }}>
              <Button
                onClick={navigateToMap}
                variant="outlined"
                sx={{ my: 1, mr: 2, minWidth: 24 }}
              >
                &lt;
              </Button>

              <Typography
                variant="h4"
                component="h1"
                gutterBottom
                sx={{ flex: "auto", alignSelf: "center", mb: 0 }}
              >
                {circonscription.name[locale]}
              </Typography>

              <Button onClick={navigateToMap} variant="outlined" sx={{ my: 1 }}>
                {t.map}
              </Button>
            </Box>

            <ProgressAlert stats={data.data.stats} />

            <Typography variant="h5" component="h2" gutterBottom>
              {t.suffrages}
            </Typography>

            {barChartData.length > 0 && (
              <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
                <BarChart
                  id="barchart1"
                  data={barChartData}
                  width={400}
                  height={300}
                />
              </Paper>
            )}

            <Typography variant="h5" component="h2" gutterBottom>
              {t.detail}
            </Typography>

            <CommuneTable data={data.data} />
          </Container>
        </>
      )}
    </>
  );
};

export default Circonscription;
