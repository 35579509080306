import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import circonscriptions from "../data/circonscriptions";
import useLocale from "../hooks/useLocale";

const translation = {
  fr: {
    circonscription: "Circonscription",
  },
  de: {
    circonscription: "Wahlbezirk",
  },
};

const CirconscriptionSelect = () => {
  const locale = useLocale();
  const t = translation[locale];

  let navigate = useNavigate();
  const routeChange = (slug) => {
    let path = locale === "de" ? "/de/wahlbezirk/" : "/fr/circonscription/";
    navigate(path + slug);
  };

  const circonscriptionsList = Object.entries(circonscriptions).map(
    (circonscription) => {
      const [slug, data] = circonscription;
      return {
        label: data.name[locale],
        slug: slug,
      };
    }
  );

  // console.log("communeList", communeList);

  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={circonscriptionsList}
      size="small"
      sx={{ width: "100%" }}
      onChange={(event, newValue) => {
        console.log(newValue);
        routeChange(newValue.slug);
      }}
      renderInput={(params) => (
        <TextField {...params} label={t.circonscription} />
      )}
    />
  );
};

export default CirconscriptionSelect;
